var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c("PageTitleSearch", {
        attrs: {
          "page-title": !_vm.$language ? "Tests" : "পরীক্ষা",
          placeholder: !_vm.$language
            ? "Search By Title"
            : "পরীক্ষার শিরোনাম দিয়ে সার্চ করো",
        },
        on: { searchElenent: _vm.searchExam },
      }),
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              !_vm.renderHtml
                ? _c("v-col", [
                    _c("div", { attrs: { id: "loading-wrapper" } }, [
                      _c("div", { attrs: { id: "loading-text" } }, [
                        _vm._v("LOADING"),
                      ]),
                      _c("div", { attrs: { id: "loading-content" } }),
                    ]),
                  ])
                : _vm.noExamCondition
                ? _c(
                    "v-col",
                    [
                      _c("no-content-component", {
                        attrs: { message: _vm.noExamMessage },
                      }),
                    ],
                    1
                  )
                : _vm._l(_vm.filterExams, function (exam, i) {
                    return _c(
                      "v-col",
                      { key: i, attrs: { cols: _vm.colsNum } },
                      [
                        _c("m-exam-list-item", {
                          attrs: {
                            exam: _vm.filterExams[i],
                            batch_id: _vm.batch_id,
                          },
                        }),
                      ],
                      1
                    )
                  }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }