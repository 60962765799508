<template>
  <v-container>
    <PageTitleSearch
      :page-title="!$language ? 'Tests' : 'পরীক্ষা'"
      :placeholder="
        !$language ? 'Search By Title' : 'পরীক্ষার শিরোনাম দিয়ে সার্চ করো'
      "
      @searchElenent="searchExam"
    ></PageTitleSearch>
    <v-container>
      <v-row>
        <v-col v-if="!renderHtml">
          <div id="loading-wrapper">
            <div id="loading-text">LOADING</div>
            <div id="loading-content"></div>
          </div>
        </v-col>
        <v-col v-else-if="noExamCondition">
          <no-content-component :message="noExamMessage"></no-content-component>
        </v-col>
        <v-col v-else v-for="(exam, i) in filterExams" :key="i" :cols="colsNum">
          <m-exam-list-item
            :exam="filterExams[i]"
            :batch_id="batch_id"
          ></m-exam-list-item>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import _ from "lodash";
import examService from "#ef/exam/services/ExamService";
import PageTitleSearch from "@ef/global/components/PageTitleSearch.vue";
import MExamListItem from "#ef/exam/components/MExamListItem";
import NoContentComponent from "/global/components/NoContentComponent";

export default {
  name: "MExam",
  components: { PageTitleSearch, MExamListItem, NoContentComponent },
  data() {
    return {
      link_dialog_visible: false,
      saving: false,
      shareExamPanel: false,
      examToShare: undefined,
      share_idx: undefined,
      sharing: {},
      unsharing: {},
      examDeleteDialog: false,
      examToDelete: undefined,
      deleting: false,
      examsData: [],
      exams: [],
      examHistory: {},
      searchInput: "",
      filterExams: [],
      renderHtml: false
    };
  },
  computed: {
    currentWidth() {
      return this.$vuetify.breakpoint.width;
    },
    colsNum() {
      return this.currentWidth < 800
        ? 12
        : this.currentWidth < 1280
        ? 12
        : this.currentWidth < 1920
        ? 6
        : 4;
    },
    examRowCols() {
      return this.shareExamPanel ? 8 : 9;
    },
    share_panel_key() {
      if (!this.examToShare) {
        return "undefined";
      } else {
        return (
          this.examToShare.object_id +
          "#" +
          this.examToShare.batches.map(b => b.shared + "").join("#")
        );
      }
    },
    noExamCondition() {
      return this.exams === undefined || this.exams.length === 0;
    },
    noExamMessage() {
      return !this.$language
        ? "You currently have no exams"
        : "বর্তমানে আপনার কোনো পরীক্ষা নেই";
    }
  },

  watch: {
    searchInput: {
      handler(newValue) {
        this.filterExams = this.exams.filter(exam =>
          exam.exam_title.toLowerCase().match(newValue.toLowerCase())
        );
      }
    }
  },

  async created() {
    this.fetchSharedExams();
  },

  methods: {
    searchExam(searchInput) {
      this.searchInput = searchInput;
    },

    async fetchSharedExams() {
      try {
        this.renderHtml = false;
        let content = await examService.list_exams_with_all_informations(
          this.$ielts_batch_id
        );
        this.exams = content.exams;
        this.filterExams = this.exams;
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.renderHtml = true;
      }
    }
  }
};
</script>
